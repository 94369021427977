import * as React from 'react'
import Helmet from 'react-helmet'

import { Links } from '../components/Links'
import { Footer } from '../components/Footer'
import LinksLayout from '../layouts'

const LinksPage: React.FC = () => {
  return (
    <LinksLayout>
      <Helmet meta={[{ property: 'og:url', content: 'https://www.codehop.net/' }]} />
      <Links />
      <Footer />
    </LinksLayout>
  )
}

export default LinksPage
