import * as React from 'react'
import { Link } from 'gatsby'
import { FaExternalLinkAlt } from 'react-icons/fa'

import { hrefStyle } from './Href.module.scss'

interface HrefProps {
  href: string
  desc: string
}

export const Href: React.SFC<HrefProps> = ({ href, desc }) => (
  <Link
    className={hrefStyle}
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    title="{href}"
  >
  { href.length > 6 && href.slice(4,6) == "s:" ? <img alt="alt" src={href + "/favicon.ico"} />
  : <FaExternalLinkAlt /> } {desc}
  </Link>
)
