import * as React from 'react'

import { Container } from '../Container'
import { Col } from '../Col'
import { Href } from '../Href'
import { FullScreenSection } from '../FullScreenSection'

export const Links = () => (
    <FullScreenSection>
    <Container>
	<h1>Links (in no particular order)</h1>
        <p><Href href="https://www.openbsd.org" desc="OpenBSD: a secure operating system." />
        <br /><Href href="https://www.archive.org/" desc="The Internet Archive" />
        <br /><Href href="http://underground-book.net/" desc="Underground: a very good read from Australian author Suelette Dreyfus." />
        <br /><Href href="http://www.vsource.org/" desc="V-Source: the place on the Web for info on Honda V4s" />
        <br /><Href href="https://news.ycombinator.com" desc="Hacker News: news for hackers" />
        <br /><Href href="https://kvrx.org" desc="KVRX 91.7 student radio: &quot;None of the hits, all of the time.&quot;" />
	<br /><Href href="http://textfiles.com" desc="Textfiles.com: a historical collection of texts from the early days of on-line." />
	<br /><Href href="https://www.airtech-streamlining.com" desc="Airtech Streamlining: motorcycle fiberglass fairings and more." />
	<br /><Href href="http://tech-insider.org" desc="Collection of historical articles, ads, etc." />
        </p>
    </Container>
    </FullScreenSection>
)
